$(function () {

    $('#save-new-preset').click(function (e) {
        if ($('#preset-interface').is(":visible")) {
            $('#preset-interface').hide();
        } else {
            $('#preset-interface').show();
            $("#new-preset").focus();
        }
        e.preventDefault();
    });

    $(document).on('click', '.filter-trash', function (e) {
        e.preventDefault();
        var trash = this;

        addSpinnerToTrash(trash);

        $.ajax({
            url: '/UserAnalysis/remove/' + $(trash).attr('data-id'),
            success: function (response) {
                if (response.msg === 'removed') {
                    $(trash).closest("li").remove();
                } else {
                    removeSpinnerFromTrash(trash);
                    alertify.error(response.msg);
                }
            },
            error: function () {
                alertify.error("There was an error storing your preset");
                removeSpinnerFromTrash(trash);
            }
        });
    });

    function addSpinnerToTrash(element) {
        $(element).removeClass('fa-trash').addClass('fa-circle-notch fa-spin');
    }

    function removeSpinnerFromTrash(element) {
        $(element).removeClass('fa-circle-notch fa-spin').addClass('fa-trash');
    }

    $('#new-preset').keypress(function (e) {
        if (e.which === 13) {
            e.preventDefault();
            storeNewPreset();
        }
    });

    function storeNewPreset() {
        var newPreset = $("#new-preset").val();
        var hash = $("#hash").html();

        addSpinnerToSavePreset();

        $.ajax({
            url: '/UserAnalysis/save/' + hash + '/' + encodeURIComponent(newPreset) + '/0',
            success: function (response) {
                if (response.msg === 'saved') {
                    $('#preset-interface').hide();
                    $("#new-preset").val('');
                    addNewPresetToMenu(newPreset, hash, response.id);
                } else {
                    alertify.error(response.msg);
                }
                removeSpinnerFromSavePreset();
            },
            error: function () {
                alertify.error("There was an error storing your preset");
                removeSpinnerFromSavePreset();
            }
        });
    }

    function addSpinnerToSavePreset() {
        $('#new-preset-icon').removeClass('fa-plus').addClass('fa-circle-notch fa-spin');
    }

    function removeSpinnerFromSavePreset() {
        $('#new-preset-icon').removeClass('fa-circle-notch fa-spin').addClass('fa-plus');
    }

    function addNewPresetToMenu(newPreset, hash, id) {
        var ts = getUnixTime();
        var content = '<li id="' + ts + '"><a href="/data/analysis/' + hash + '">';
        content += '<span class="pull-right fa fa-trash filter-trash" data-id="' + id + '"></span>';
        content += newPreset + '</a></li>';
        $(content).insertBefore("#presets-spacer");
        flash("#" + ts);
    }

    function getUnixTime() {
        return Math.round((new Date()).getTime() / 1000);
    }

    function flash(elements) {
        var opacity = 100;
        var color = "137, 197, 64";
        var interval = setInterval(function () {
            opacity -= 3;
            if (opacity <= 0) {
                clearInterval(interval);
            }
            $(elements).css({background: "rgba(" + color + ", " + opacity / 100 + ")"});
        }, 30);
    }

    $(document).on('click', '#filter-presets-wrapper .dropdown-menu', function (e) {
        e.stopPropagation();
    });

});
